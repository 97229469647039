import * as t from 'io-ts';
import { parseDateFromApi } from '../../../global/helpers/date.helper';
import { FamilyList } from '../../family/models/family-list.model';
import { GroupMinList, GroupMinListType } from '../../groups/models/group-min-list.model';
import { MinistryMinList, MinistryMinListType } from '../../ministries/models/ministry-min-list.model';
import { UserList, UserListType } from '../../users/models/user-list.model';
import { MemberListType } from './member-list.model';
import { MemberSex, MemberSexItem } from './member-sex.model';
import { MemberStatus, MemberStatusItem } from './member-status.model';
import { AuthService } from '../../../auth/services/AuthService';

export const MemberCardType = t.intersection([
    MemberListType,
    t.type({
        patronymic: t.union([t.string, t.null]),
        avatar: t.union([t.string, t.null]),
        sex: t.union([t.string, t.null]),
        bDay: t.union([t.string, t.number, t.null]),
        birthPlace: t.union([t.string, t.null]),
        skills: t.union([t.string, t.undefined, t.null]),
        repentanceYear: t.union([t.string, t.undefined, t.null]),
        repentancePlace: t.union([t.string, t.undefined, t.null]),
        baptismYear: t.union([t.string, t.undefined, t.null]),
        witnessesDate: t.union([t.string, t.undefined, t.number, t.null]),
        disciplineDate: t.union([t.string, t.undefined, t.number, t.null]),
        disciplineReason: t.union([t.string, t.undefined, t.null]),
        adoptedDate: t.union([t.string, t.undefined, t.number, t.null]),
        disposalDate: t.union([t.string, t.undefined, t.number, t.null]),
        groups: t.union([t.array(GroupMinListType), t.undefined]),
        ministries: t.union([t.array(MinistryMinListType), t.undefined]),
        ministriesLead: t.union([t.array(MinistryMinListType), t.null, t.undefined]),
        ministriesAssist: t.union([t.array(MinistryMinListType), t.null, t.undefined]),
        groupLead: t.union([GroupMinListType, t.null, t.undefined]),
        groupAssist: t.union([GroupMinListType, t.null, t.undefined]),
        user: t.union([UserListType, t.null, t.undefined]),
        notCongratulation: t.union([t.boolean, t.undefined, t.null]),
        aboutMe: t.union([t.string, t.null]),
        completeAu: t.union([t.boolean, t.undefined, t.null]),
        username: t.union([t.string, t.undefined, t.null]),
    })
]);

export type MemberCardDTO = t.TypeOf<typeof MemberCardType>;

class MemberCard {
    id: number;
    firstName: string;
    lastName: string;
    patronymic: string | null;
    avatar: string | null;
    phone: string;
    status: MemberStatusItem;
    email: string | null;
    sex: MemberSexItem | null;
    birthDay: Date | null;
    birthPlace: string | null;
    skills: string | null;
    repentanceYear: string | null;
    repentancePlace: string | null;
    baptismYear: string | null;
    witnessesDate: Date | null;
    adoptedDate: Date | null;
    disposalDate: Date | null;
    disciplineDate: Date | null;
    disciplineReason: string | null;
    family: FamilyList | null;
    groups: GroupMinList[];
    ministries: MinistryMinList[];
    ministriesLead: MinistryMinList[];
    ministriesAssist: MinistryMinList[];
    groupLead: GroupMinList[];
    groupAssist: GroupMinList[];
    user: UserList | null;
    aboutMe: string | null;
    username: string | null;
    isMyCard: boolean;
    confirmationIsFalse: boolean;
    outsideGroups: boolean;
    outsideMinistries: boolean;
    pastorNote: string;
    donatedRecently: boolean;
    notCongratulation: boolean;
    completeAu: boolean;
    rbcBaptism: boolean;

    constructor(dto: MemberCardDTO) {
        this.id = dto.id;
        this.isMyCard = AuthService.getUserInfo()?.member?.id === this.id;
        // имя
        this.firstName = dto.firstName || '';
        // фамилия
        this.lastName = dto.lastName || '';
        // отчество
        this.patronymic = dto.patronymic;
        // фотка
        this.avatar = dto.avatar;
        // телефон
        this.phone = dto.phone || '';
        // пасторская забота
        this.pastorNote = dto.pastorNote || '';
        // статус в церкви
        this.status = dto.status && MemberStatus[dto.status] ? MemberStatus[dto.status] : MemberStatus.GUEST;
        // почта
        this.email = dto.email;
        // пол
        this.sex = dto.sex && MemberSex[dto.sex] ? MemberSex[dto.sex] : null;
        // дата рождения
        this.birthDay = parseDateFromApi(dto.bDay);
        // место рождения
        this.birthPlace = dto.birthPlace;
        // семья
        this.family = dto.family ? new FamilyList(dto.family) : null;
        // способности
        this.skills = dto.skills || '';
        // год покаяния
        this.repentanceYear = dto.repentanceYear || null;
        // место покаяния
        this.repentancePlace = dto.repentancePlace || null;
        // год крещения
        this.baptismYear = dto.baptismYear || null;
        // дата рассказывания свидетельства
        this.witnessesDate = parseDateFromApi(dto.witnessesDate);
        // дата принятия в члены церкви
        this.adoptedDate = parseDateFromApi(dto.adoptedDate);
        // дата отбытия
        this.disposalDate = parseDateFromApi(dto.disposalDate);
        // дата дисциплины
        this.disciplineDate = parseDateFromApi(dto.disciplineDate);
        // причина дисциплины
        this.disciplineReason = dto.disciplineReason || '';
        // группы, в которых состоит участник
        this.groups = dto.groups ? dto.groups.map(el => new GroupMinList(el)) : [];
        // служения, в которых состоит участник
        this.ministries = dto.ministries ? dto.ministries.map(el => new MinistryMinList(el)) : [];
        // информация о руководстве в какой-нибудь группе или в служении
        this.ministriesLead = dto.ministriesLead?.map(el => new MinistryMinList(el)) || [];
        this.ministriesAssist = dto.ministriesAssist?.map(el => new MinistryMinList(el)) || [];
        this.groupLead = dto.groupLead ? [new GroupMinList(dto.groupLead)] : [];
        this.groupAssist = dto.groupAssist ? [new GroupMinList(dto.groupAssist)] : [];
        this.user = dto.user ? new UserList(dto.user) : null;
        this.aboutMe = dto.aboutMe || null;
        this.confirmationIsFalse = !!dto.confirmationIsFalse && (dto.status === MemberStatus.RBC.value || dto.status === MemberStatus.ON_REMARK.value);
        this.outsideGroups = !!dto.outsideGroups && (dto.status === MemberStatus.RBC.value || dto.status === MemberStatus.ON_REMARK.value);
        this.outsideMinistries = !!dto.outsideMinistries && (dto.status === MemberStatus.RBC.value || dto.status === MemberStatus.ON_REMARK.value);
        this.notCongratulation = !!dto.notCongratulation;
        this.rbcBaptism = !!dto.rbcBaptism;
        this.completeAu = !!dto.completeAu;
        this.donatedRecently = !!dto.donatedRecently;
        this.username = dto.username || null;
    }

    get name() {
        let result: string = '';
        if (!this) {
            return result;
        }
        if (this.lastName) {
            result = `${this.lastName}`;
        }
        if (this.firstName) {
            result = `${result}${result ? ' ' : ''}${this.firstName}`;
        }
        if (this.patronymic) {
            result = `${result}${result ? ' ' : ''}${this.patronymic}`;
        }
        return result;
    }
}

export { MemberCard };
